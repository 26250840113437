$base-unit: 1;

body {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
}

.popover-body {
  font-family: $font-family-monospace;
}

em {
  font-style: italic;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  hyphens: auto;
}
