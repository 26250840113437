.navbar {
  gap: var(--gap);
  justify-content: space-between;
}

.navbar-brand {
  position: relative;
  z-index: 1;

  .icon {
    margin-right: 0.3rem;
  }
}
