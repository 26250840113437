/* Animate.css https://github.com/daneden/animate.css */
@keyframes a {
  0%,
  50%,
  to {
    opacity: 1;
  }

  25%,
  75% {
    opacity: 0.7;
  }
}

@keyframes a {
  0%,
  50%,
  to {
    opacity: 1;
  }

  25%,
  75% {
    opacity: 0.7;
  }
}

.flash {
  animation-duration: 5s;
  animation-fill-mode: both;
  animation-iteration-count: infinite;
  animation-name: a;
}
