:root {
  --color-light: #ececec;
  --color-light-hover: #fff;
  --col: 10rem;
  --gap: 1rem;

  // --body-color: #555;
  --color-dark: #444343;
  --color-dark-hover: rgb(53 53 53);
  --primary-color: #14488d;
  --theme-bg-color: var(--color-dark);
  --theme-text-color: var(--color-light);
}

$input-bg: var(--color-dark);
$input-color: var(--color-light);
$input-placeholder-color: var(--color-light);
$pre-color: var(--color-light);
$pagination-color: var(--theme-text-color);
$pagination-bg: var(--color-dark-hover);
$pagination-disabled-color: rgb(var(--theme-bg-color) 0.5);
$pagination-disabled-bg: rgb(var(--theme-bg-color) 0.7);
$pagination-disabled-border-color: rgb(var(--theme-bg-color) 0.2);
$pagination-hover-color: $link-hover-color !default;
$pagination-hover-bg: $gray-200 !default;
$pagination-hover-border-color: $gray-300 !default;

// $pagination-border-color: var(--theme-bg-color);
$pagination-active-color: var(--theme-text-color);
$pagination-active-bg: rgb(var(--theme-bg-color) 0.7);
$pagination-active-border-color: var(--color-dark-hover);
$card-border-color: rgb(var(--color-light) 0.125);
$card-cap-bg: rgb(var(--color-dark) 0.03);
$card-bg: var(--color-dark);
$popover-bg: var(--theme-bg-color);
$popover-body-color: var(--theme-text-color);
$popover-border-color: rgb(var(--theme-bg-color) 0.2);
$popover-arrow-color: var(--theme-bg-color);
$dropdown-bg: var(--theme-bg-color);
$dropdown-link-color: var(--theme-text-color);
$modal-content-bg: var(--color-dark-hover);
$enable-flex: true;
$enable-shadows: true;
$font-prefix: "../fonts/";
$blue: #14488d;
$breadcrumb-bg: transparent;
$modal-lg: 80vw;
$transition-time: 0.3s;
$table-cell-padding: 0.4rem;
$popover-max-width: 60rem;
$border-radius: 0.5rem;
$card-bg: var(--color-dark-hover);
$card-header-bg: var(--theme-bg-color);
$link-color: var(--theme-text-color);
$link-decoration: none;
$link-hover-color: var(--color-light-hover);
$link-hover-decoration: none;
$table-color: var(--theme-text-color);
$table-bg: var(--theme-bg-color);
$table-accent-bg: rgb(var(--theme-bg-color) 0.7);
$table-hover-color: var(--color-light-hover);
$table-hover-bg: rgba($white, 0.075) !default;
$table-border-color: rgb(var(--theme-text-color) 0.8);
$table-color: var(--theme-text-color);
$table-hover-bg: rgb(var(--theme-text-color) 0.5);
