/* icons */
.icon {
  display: inline-block;
  vertical-align: middle;
  width: 1.15rem;
  height: 1.15rem;
  margin-top: -0.125rem;
  fill: currentcolor;

  a > & {
    fill: currentcolor;
  }

  a:hover > & {
    fill: currentcolor;
  }

  &--success {
    fill: var(--success);
  }

  &--warning,
  .list-group-item-warning & {
    fill: darken($warning, 3%); // warning-Farbe des Icons etwas dunkler
  }

  &--danger {
    fill: var(--danger);
  }

  &--light {
    fill: var(--light);
  }

  &--white {
    fill: var(--white);
  }

  .list-group-item-success & {
    color: var(--success);
  }

  .list-group-item-warning & {
    color: var(--warning);
  }

  .list-group-item-danger & {
    color: var(--danger);
  }

  .btn-light & {
    color: var(--dark);
  }

  .btn-primary & {
    color: currentcolor;
  }
}

.icon--gradient *,
a .icon--gradient * {
  fill: url(#lgrad);
}

.disabled a .icon,
.icon--light {
  fill: var(--light);
}
