/* Cards */
.card-header {
  align-items: center;
  padding: 0.4rem 0.5rem;
  justify-content: baseline;
  background-color: transparent;

  p {
    margin-bottom: 0;
    font-style: italic;
  }

  ul {
    list-style: none outside;
    margin-bottom: 0;
  }
}

.card-title {
  margin-bottom: 0;
  font-variant: small-caps;
  letter-spacing: 0.1rem;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

@media (min-width: 960px) {
  /* expand dropdown menu with tags on larger displays */
  .card-header {
    .dropdown-menu {
      display: inline-flex;
      justify-content: flex-end;
      padding: unset;
      margin: unset;
      background-color: transparent;
      float: none;
      min-width: 1rem;
      border-radius: 0;
      position: relative;
      box-shadow: none;
      border: none;

      a {
        padding: 0.05rem 0.1rem;
      }
    }

    .dropdown-toggle {
      display: none;
    }
  }
}

.card-header .dropdown-menu.show {
  display: inline-flex;
  justify-content: space-around;
}

.card-body {
  max-height: 50vh;
  overflow: auto;

  .situation {
    text-overflow: ellipsis;
    white-space: pre-wrap;
  }

  h5 {
    display: flex;
    justify-content: space-between;
  }

  * {
    color: var(--light) 1;
  }
}

@media (max-width: 960px) {
  .card-header nav ul {
    display: none;
  }

  .card-header nav select {
    display: inline-block;
  }
}

// Fancy on the dashboard
.home .card {
  position: relative;
  box-shadow: 0 1px 2px rgb(0 0 0 / 20%);
  transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
  will-change: transform, opacity;

  &::after {
    content: "";
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    box-shadow: 0 5px 15px rgb(0 0 0 / 20%);
    opacity: 0;
    transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
  }

  &:hover {
    // transform: scale(1.05, 1.05);
    transform: translate(0, -0.5rem);

    // box-shadow: 0 15px 45px -5px rgb(10 16 34 / 25%);
    box-shadow: rgb(54 45 89 / 15%) 0 2px 0, rgb(54 45 89 / 20%) 0 0 100px, var(--yellow) -0.1875rem -0.1875rem 0 0.1875rem, var(--orange) 0 0 0 0.375rem;
    z-index: 1;

    &::after {
      opacity: 1;
    }
  }
}

.home .tooltip.show {
  left: -10px !important;
}
