body,
html {
  min-height: 100%;
}

body {
  @include grid;

  box-sizing: border-box;
  height: 100%;
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  width: calc(100% - 1.25rem);
  margin: 0 auto;
  grid-template-areas: "header" "content" "footer";
  grid-template-columns: 1fr;
  grid-template-rows: auto 1fr auto;
  gap: var(--gap);
}

main {
  @include grid;

  grid-area: content;
  grid-template-columns: 1fr;
  grid-auto-rows: min-content;
  gap: var(--gap);

  .grid {
    gap: calc(var(--gap) * 0.5);
  }
}

header {
  grid-area: header;
  margin-top: 1rem;
}

footer {
  grid-area: footer;
  padding: 0.5rem 0;
}

// Forms
.form--grid {
  @include grid;

  grid-template-columns: repeat(var(--template-column-count), 1fr) auto;
}

.form--grid__2 {
  --template-column-count: 2;
}

.form--grid__3 {
  --template-column-count: 3;
}

// Lists
.list--grid {
  @include grid;

  padding: 0.2rem;
  gap: 0.2rem;
  grid-template-columns: repeat(auto-fit, minmax(2rem, 1fr));
  justify-content: center;
}

.list--grid .list-group-item-danger,
.list--grid .list-group-item-warning {
  @include grid-reset;
}

@media screen and (min-width: 1280px) {
  .grid {
    --col: 12rem;

    &--fill {
      grid-template-columns: repeat(auto-fit, minmax(24rem, 1fr));
      grid-template-rows: auto-fill;
    }
  }
}
