body {
  background: var(--theme-bg-color);
  color: var(--theme-text-color);
  position: relative;
  align-items: flex-start;
  transition: background $transition-time, color $transition-time;
}

img,
source {
  max-width: 100%;
  height: auto;
}

main {
  align-items: flex-start;
}

/* Situations */
:not(.table-responsive) > .table > thead {
  position: sticky;
  z-index: 2;
  top: 0;
}

#situationTs::before {
  display: inline-block;
  content: "⏱";
  padding-right: 0.2rem;
}

#situationStatus::before {
  display: inline-block;
  content: "🤖";
  padding-right: 0.2rem;
}

pre {
  white-space: pre-wrap;
  width: 100%;
  display: block;

  html[data-theme="dark"] & {
    background-color: var(--color-dark);
  }
}

::selection {
  color: var(--light);
  background: var(--primary-color);
}

::selection {
  color: var(--light);
  background: var(--primary-color);
}

.list-group {
  width: 100%;
}

.list-group-item {
  padding: 0.3rem;
  margin: 0;
  border-radius: 0.2rem !important;

  &-success {
    text-align: center;
    font-weight: 600;
  }

  &-warning,
  &-danger {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
}

$success-colors: #d4eabe, #c2e2a3, #b2da8b, #a3d375, #95cd60, #88c74d, #7bbf3c,
  #70ae37, #669e32, #5d902e, #55832a;

@for $i from 1 through length($success-colors) {
  .list-group-item-success:nth-child(#{length($success-colors)}n + #{$i}) {
    background-color: lighten(nth($success-colors, $i), 5);
    color: reverse(nth($success-colors, $i));
  }
}

.text-white {
  fill: #fff;
}

.input-group {
  margin-right: 0.5rem;
}

/* Sticky table head */
thead {
  background: #212529;
}

footer {
  font-size: 0.85rem;
}

.chart {
  background-color: #fff;
  color: var(--dark);
  overflow: hidden;
}

.apexcharts-canvas {
  max-width: 100%;
}

/* Bootstrap Popover */
.popover-body {
  white-space: pre-line;
}

/* Bootstrap modal */
.modal pre {
  white-space: pre-wrap;
}

.modal .close,
.modal pre {
  color: var(--light);
}

.modal .close:not(:disabled):not(.disabled):focus,
.modal .close:not(:disabled):not(.disabled):hover {
  color: var(--white);
}

/* Tabellen */
.table {
  th,
  thead {
    position: sticky;
    position: sticky;
    top: 0;
  }
}

/* Timestamp */
.timestamp--overdue {
  position: fixed;
  display: block;
  z-index: 2;
  bottom: 2rem;
  right: 2rem;
  background: var(--dark);
  padding: 1rem;
  border: 0.3rem solid var(--danger);
  border-radius: 5px;
}

.badge {
  &--overdue {
    position: absolute;
    z-index: 2;
    left: 0;
    top: -0.5rem;
  }
}

/* Snapshot - Success */
.snapshot--success {
  position: fixed;
  display: block;
  z-index: 2;
  top: 1.5rem;
  right: rem;
  background: var(--dark);
  padding: 1rem;
  border: 0.3rem solid var(--success);
  border-radius: 5px;
  margin-left: 6rem;
}
